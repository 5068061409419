.blog-post{
    background-color: rgba(255,255,255,.2);
    margin: 20px;
}


@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.blog-post:hover{
  background-color: rgba(255,255,255,.4);
}

.description{
  padding: 5px;
}

.published, .creator{
    display: inline-block;
}
/* .content_container{
    margin-top: 10px;
    width:90%;
    margin-left:5%;
    position: relative;
} */

.room_content{
    text-align: center;
    color: white;
    background-color:rgba(56, 55, 53, .6);
    /* max-width:700px; */
    margin-right:auto;
    margin-left:auto;
    border-radius: 20px;
}

ul.top_topics{
    list-style-type: none;
    overflow: auto;
    margin-bottom: 0%;
    padding-left: 0px;

}

ul.top_topics li{
    display: inline;
    float: left;
    width: 100%;
}

ul.top_topics li a{
    display: inline-block;
    color: #dc7a33;
    text-decoration: none;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    /* background-color: white; */
    width: 100%;
    opacity: 0.8;
    padding-top: 15px;
    padding-bottom: 15px;

}

ul.top_topics li a:hover{
    opacity: 1.0;
    color: #f47920;
    background-color:white;
}

.find_your_room{
    height: 48px;
    line-height:48px;
    margin: auto;
    background-color: #f47920;
    font-weight: bold;
    border-radius: 10px;
    margin-top: 3%;
    width: 220px;
    box-shadow: -5px 0px 15px #312e2e;
}

.find_your_room:hover{
    cursor:pointer;
}

.find_your_room #searchText:hover{
    cursor:pointer;
}

.find_your_room form.focused #searchText:hover{
    cursor:text;
}

.find_your_room .find_your_room_tail{
    width:100%;
    height:10px;
}

.find_your_room p{
    margin:0px;
}

.find_your_room form{
    width:100%;
    position:relative;
    width: 100%;
    height: 48px;
    line-height: 48px;
    padding: 0px;
    border: 0px;
    text-align: center;
    background-color: #f47920;
    border-radius: 10px;
}

[data-authenticated] .find_your_room input,
.authenticated .find_your_room input{
    width: 100%;
    height: 46px;
    line-height: 46px;
    padding: 0px;
    border: 0px;
    text-align: center;
    background-color: #f47920;
    border-radius: 10px;
    font-family: roboto;
    font-weight: bold;
    font-size: 20px;
}
/* do not group these rules */
*::-webkit-input-placeholder {
    color: white;
}
*::-moz-placeholder {
    /* FF 19+ */
    color: white;
}
*:-ms-input-placeholder {
    /* IE 10+ */
    color: white;
}
*::placeholder{
    color:white;
}

.find_your_room, .random-room, .find_your_room + h2{
  display:none;
}
[data-authenticated] .find_your_room + h2,
.authenticated .find_your_room + h2{
  display:inherit;
}
[data-authenticated] .find_your_room, [data-authenticated] .random-room,
[data-authenticated] .find_your_room, .authenticated .random-room,
.authenticated .find_your_room, .authenticated .random-room{
  display:inline-block;
}

[data-authenticated] .find_your_room input, .find_your_room form,
.authenticated .find_your_room input, .find_your_room form{
    transition: background-color .5s ease-in-out, box-shaddow .5s ease-in-out, width .5s ease-in-out;
}

/* do not group these rules */
[data-authenticated] .find_your_room form.focused input::-webkit-input-placeholder,
.authenticated .find_your_room form.focused input::-webkit-input-placeholder {
    color: #DDDDDD !important;
    transition: color .5s ease-in-out;
}
[data-authenticated] .find_your_room form.focused input::-moz-placeholder,
.authenticated .find_your_room form.focused input::-moz-placeholder {
    /* FF 19+ */
    color: #DDDDDD !important;
    transition: color .5s ease-in-out;
}
[data-authenticated] .find_your_room form.focused input:-ms-input-placeholder,
.authenticated .find_your_room form.focused input:-ms-input-placeholder {
    /* IE 10+ */
    color: #DDDDDD !important;
    transition: color .5s ease-in-out;
}
[data-authenticated] .find_your_room form.focused input::placeholder,
.authenticated .find_your_room form.focused input::placeholder{
    color: #DDDDDD !important;
    transition: color .5s ease-in-out;
}

[data-authenticated] .find_your_room form.focused,
.authenticated .find_your_room form.focused{
    color:black;
    background-color:white;
    border-color: #f47920;
    outline: none;
    border:1px solid #f47920;
    box-shadow: 0 0 10px #f47920;
    line-height: 46px;
    height: 46px;
    text-align: left;
}

[data-authenticated] .find_your_room form.focused #searchText,
.authenticated .find_your_room form.focused #searchText{
    color:black;
    background-color:white;
    border-color: #f47920;
    outline: none;
    width:170px;
    margin-left:5px;
    text-align: left;
}
[data-authenticated] .find_your_room form #search,
.authenticated .find_your_room form #search{
    display:none;
}

[data-authenticated] .find_your_room form.focused #search,
.authenticated .find_your_room form.focused #search{
    height:40px;
    width:40px;
    background-image: url('/img/search-01.svg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: 30px 30px;
    position:absolute;
    right:5px;
    top:3px;
    background-color:white;
    display:block;
}

#inputOverview{
    text-align: right;
    margin: auto;
    margin-top: 10px;
    width: 220px;
}
.too-many-chars{
    color:red;
}

[data-authenticated] #search:hover,
.authenticated #search:hover{
    cursor:pointer;
}

.hide{
    display:none;
}

svg {
    fill:#f47920;
    position: relative;
    top: -20px;
}

h1{
    padding-top: 15px;
    font-size: 36px;
    margin-bottom: 10px;
    font-weight: bold;
}
.greeting{
    font-size:16px;
    max-width:370px;
    margin:auto;
    font-weight: bold;
}
p{
    color: white;
    font-family: sans-serif;
}
body{
    z-index:1;
}

[data-authenticated] .random-room,
.authenticated .random-room{
  margin: auto;
  width: 220px;
  margin-top: 13px;
  color: white;
  border-radius: 5px;
  border: 3px solid #f47920;
  background-color: #f47920;
  margin-bottom:3px;
}

[data-authenticated] .room_content a,
.authenticated .room_content a{
  height: 42px;
  line-height: 42px;
  /* width:100%; */
  color: white;
  text-decoration: none;
  font-family: sans-serif;
  font-weight: bold;
}

[data-authenticated] .room_content a > div,
.authenticated .room_content a > div{
  margin-top: 0px;
}

[data-authenticated] .random-room:hover,
.authenticated .random-room:hover{
    background-color: white;
    border: 3px solid #f47920;
    cursor:pointer;
    margin-bottom:0px;
}

[data-authenticated] .room_content a:hover .random-room,
.authenticated .room_content a:hover .random-room{
    color: #f47920;
}

@media (min-width: 380px){
    ul.top_topics li{
        width: 50%;
    }
}

/* topic-room-list{
  scroll-snap-align: center;
} */

topic-room-list{
  transition: left 1s ease, top 1s ease;
  top: 0px;
  left: 0px;
  height: 40vh;
  display: block;
  /* margin-top: 6px; */
  scroll-snap-align: center;
  /* margin-left: 5%; */
  /* width: 100vw; */
  width: calc(100vw - 10px);
}

topic-room-list topic-room[condensed="true"]{
  height:60px;
  overflow: hidden;
}

topic-room-list topic-room[mainstream="true"]{
  height:45vh;
  overflow: hidden;
}

/* topic-room:nth-child(1){
  height:45vh;
} */

ul.top_topics li{
    display: none;
}

@media (min-width: 580px){
    ul.top_topics li{
        width: 33.33%;
    }
    topic-room-list{
      transition: left 1s ease, top 1s ease;
      top: 0px;
      left: 0px;
      height:45vh;
      /* height: 90vh; */
      display: block;
      scroll-snap-align: center;
      /* margin-left: 5%; */
      /* width: 90vw; */
      margin-bottom: 0vh;
    }

}

@media (min-width: 620px){
  /* topic-room-list{
    height:30vh;
  } */
}

@media (min-width: 750px){
  topic-room-list{
    height: 35vh;
  }
  /* topic-room-list{
    width: 47vw;
    margin-left: 1vw;
    margin-bottom: 0.5vh;
    height:60vh;
    margin-right: 0px;
    display: inline-block;
    overflow:hidden;
  } */
  /* topic-room#trending-chat{
    width:94vw;
    height:30vh;
  } */
}

@media (min-width: 840px){
    ul.top_topics li{
        width: 16.66%;
    }
}
@media (min-width:1060px){
  /* body topic-room-list{
    height:40vh;
  } */
  /* body topic-room#trending-chat{
    height:20vh;
  } */
  body[data-authenticated] topic-room-list,
  body.authenticated topic-room-list{
    /* height:60vh; */
    width: calc(100vw - 325px);
    padding: 0px;
    padding-top:10px
  }
  /* body.authenticated topic-room#trending-chat{
    height:30vh;
    width: calc((100vw - 280px) - 7vw);
  } */
}
