.blog-post {
  background-color: #fff3;
  margin: 20px;
}

@property --angle {
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}

.blog-post:hover {
  background-color: #fff6;
}

.description {
  padding: 5px;
}

.published, .creator {
  display: inline-block;
}

.room_content {
  text-align: center;
  color: #fff;
  background-color: #38373599;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
}

ul.top_topics {
  margin-bottom: 0%;
  padding-left: 0;
  list-style-type: none;
  overflow: auto;
}

ul.top_topics li {
  float: left;
  width: 100%;
  display: inline;
}

ul.top_topics li a {
  color: #dc7a33;
  text-align: center;
  width: 100%;
  opacity: .8;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: sans-serif;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

ul.top_topics li a:hover {
  opacity: 1;
  color: #f47920;
  background-color: #fff;
}

.find_your_room {
  height: 48px;
  width: 220px;
  background-color: #f47920;
  border-radius: 10px;
  margin: 3% auto auto;
  font-weight: bold;
  line-height: 48px;
  box-shadow: -5px 0 15px #312e2e;
}

.find_your_room:hover, .find_your_room #searchText:hover {
  cursor: pointer;
}

.find_your_room form.focused #searchText:hover {
  cursor: text;
}

.find_your_room .find_your_room_tail {
  width: 100%;
  height: 10px;
}

.find_your_room p {
  margin: 0;
}

.find_your_room form {
  width: 100%;
  width: 100%;
  height: 48px;
  text-align: center;
  background-color: #f47920;
  border: 0;
  border-radius: 10px;
  padding: 0;
  line-height: 48px;
  position: relative;
}

[data-authenticated] .find_your_room input, .authenticated .find_your_room input {
  width: 100%;
  height: 46px;
  text-align: center;
  background-color: #f47920;
  border: 0;
  border-radius: 10px;
  padding: 0;
  font-family: roboto;
  font-size: 20px;
  font-weight: bold;
  line-height: 46px;
}

::-webkit-input-placeholder {
  color: #fff;
}

::-moz-placeholder {
  color: #fff;
}

:-ms-input-placeholder {
  color: #fff;
}

::placeholder {
  color: #fff;
}

.find_your_room, .random-room, .find_your_room + h2 {
  display: none;
}

[data-authenticated] .find_your_room + h2, .authenticated .find_your_room + h2 {
  display: inherit;
}

[data-authenticated] .find_your_room, [data-authenticated] .random-room, [data-authenticated] .find_your_room, .authenticated .random-room, .authenticated .find_your_room, .authenticated .random-room {
  display: inline-block;
}

[data-authenticated] .find_your_room input, .find_your_room form, .authenticated .find_your_room input, .find_your_room form {
  transition: background-color .5s ease-in-out, box-shaddow .5s ease-in-out, width .5s ease-in-out;
}

[data-authenticated] .find_your_room form.focused input::-webkit-input-placeholder, .authenticated .find_your_room form.focused input::-webkit-input-placeholder {
  transition: color .5s ease-in-out;
  color: #ddd !important;
}

[data-authenticated] .find_your_room form.focused input::-moz-placeholder, .authenticated .find_your_room form.focused input::-moz-placeholder {
  transition: color .5s ease-in-out;
  color: #ddd !important;
}

[data-authenticated] .find_your_room form.focused input:-ms-input-placeholder, .authenticated .find_your_room form.focused input:-ms-input-placeholder {
  transition: color .5s ease-in-out;
  color: #ddd !important;
}

[data-authenticated] .find_your_room form.focused input::placeholder, .authenticated .find_your_room form.focused input::placeholder {
  transition: color .5s ease-in-out;
  color: #ddd !important;
}

[data-authenticated] .find_your_room form.focused, .authenticated .find_your_room form.focused {
  color: #000;
  height: 46px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #f47920;
  outline: none;
  line-height: 46px;
  box-shadow: 0 0 10px #f47920;
}

[data-authenticated] .find_your_room form.focused #searchText, .authenticated .find_your_room form.focused #searchText {
  color: #000;
  width: 170px;
  text-align: left;
  background-color: #fff;
  border-color: #f47920;
  outline: none;
  margin-left: 5px;
}

[data-authenticated] .find_your_room form #search, .authenticated .find_your_room form #search {
  display: none;
}

[data-authenticated] .find_your_room form.focused #search, .authenticated .find_your_room form.focused #search {
  height: 40px;
  width: 40px;
  background-color: #fff;
  background-image: url("search-01.84244e17.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  display: block;
  position: absolute;
  top: 3px;
  right: 5px;
}

#inputOverview {
  text-align: right;
  width: 220px;
  margin: 10px auto auto;
}

.too-many-chars {
  color: red;
}

[data-authenticated] #search:hover, .authenticated #search:hover {
  cursor: pointer;
}

.hide {
  display: none;
}

svg {
  fill: #f47920;
  position: relative;
  top: -20px;
}

h1 {
  margin-bottom: 10px;
  padding-top: 15px;
  font-size: 36px;
  font-weight: bold;
}

.greeting {
  max-width: 370px;
  margin: auto;
  font-size: 16px;
  font-weight: bold;
}

p {
  color: #fff;
  font-family: sans-serif;
}

body {
  z-index: 1;
}

[data-authenticated] .random-room, .authenticated .random-room {
  width: 220px;
  color: #fff;
  background-color: #f47920;
  border: 3px solid #f47920;
  border-radius: 5px;
  margin: 13px auto 3px;
}

[data-authenticated] .room_content a, .authenticated .room_content a {
  height: 42px;
  color: #fff;
  font-family: sans-serif;
  font-weight: bold;
  line-height: 42px;
  text-decoration: none;
}

[data-authenticated] .room_content a > div, .authenticated .room_content a > div {
  margin-top: 0;
}

[data-authenticated] .random-room:hover, .authenticated .random-room:hover {
  cursor: pointer;
  background-color: #fff;
  border: 3px solid #f47920;
  margin-bottom: 0;
}

[data-authenticated] .room_content a:hover .random-room, .authenticated .room_content a:hover .random-room {
  color: #f47920;
}

@media (min-width: 380px) {
  ul.top_topics li {
    width: 50%;
  }
}

topic-room-list {
  height: 40vh;
  scroll-snap-align: center;
  width: calc(100vw - 10px);
  transition: left 1s, top 1s;
  display: block;
  top: 0;
  left: 0;
}

topic-room-list topic-room[condensed="true"] {
  height: 60px;
  overflow: hidden;
}

topic-room-list topic-room[mainstream="true"] {
  height: 45vh;
  overflow: hidden;
}

ul.top_topics li {
  display: none;
}

@media (min-width: 580px) {
  ul.top_topics li {
    width: 33.33%;
  }

  topic-room-list {
    height: 45vh;
    scroll-snap-align: center;
    margin-bottom: 0;
    transition: left 1s, top 1s;
    display: block;
    top: 0;
    left: 0;
  }
}

@media (min-width: 750px) {
  topic-room-list {
    height: 35vh;
  }
}

@media (min-width: 840px) {
  ul.top_topics li {
    width: 16.66%;
  }
}

@media (min-width: 1060px) {
  body[data-authenticated] topic-room-list, body.authenticated topic-room-list {
    width: calc(100vw - 325px);
    padding: 10px 0 0;
  }
}

/*# sourceMappingURL=home.7c42b8fa.css.map */
